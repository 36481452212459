import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from "react-native";
import Icon from 'react-native-vector-icons/Ionicons';

const FloatingBackButton = ({navigation, disabled}) => {
  return (
    <TouchableOpacity 
      onPress={() => navigation.goBack()}
      disabled={disabled}
      style={disabled ? styles.floatingBackButtonDisabledContainer : styles.floatingBackButtonContainer}>
      <Icon size={20} color="#ffffff" name='chevron-back-outline'></Icon>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  floatingBackButtonContainer: {
    elevation: 8,
    backgroundColor: "#E34A4A",
    borderRadius: 25,
    paddingVertical: 8,
    paddingHorizontal: 10,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    marginTop: 5,
    height: 40,
    width: 40
  },
  floatingBackButtonDisabledContainer: {
    elevation: 8,
    backgroundColor: "#cccccc",
    borderRadius: 25,
    paddingVertical: 8,
    paddingHorizontal: 10,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    marginTop: 5,
    height: 40,
    width: 40
  }
});

export default FloatingBackButton;