import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cloneDeep from 'lodash/cloneDeep';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import DateInput from '../components/DateInput';
import Select from '../components/Select';
import DecimalInput from '../components/DecimalInput';
import DescriptionInput from '../components/DescriptionInput';
import PrimaryButton from '../components/PrimaryButton';
import ErrorButton from '../components/ErrorButton';
import FormLabel from '../components/FormLabel';
import PickerSwitchButton from '../components/PickerSwitchButton';
import FloatingBackButton from '../components/FloatingBackButton';
import { ListItem } from 'react-native-elements';
import ErrorIconButton from '../components/ErrorIconButton';
  
class Stock extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stock: null,
      stockItem: {
        id: 0,
        item_id: "",
        warehouse_id: "",
        location_id: "",
        quantity: ""
      },
      items: [],
      warehouses: [],
      locations: [],
      stockReasons: []
    };
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => { 
      console.log("Stock componentDidMount fired");
      const me = this;
      me.setState({
        stock: cloneDeep(me.props.route.params.stock),
        loading: true
      });
      me.props.route.params.apiRequest.get("item").then(function(response) {
        me.setState({
          items: response
        });
        me.props.route.params.apiRequest.get("warehouse").then(function(response) {
          me.setState({
            warehouses: response
          });
          me.props.route.params.apiRequest.get("location").then(function(response) {
            me.setState({
              locations: response
            });
            me.props.route.params.apiRequest.get("stock-reason").then(function(response) {
              me.setState({
                stockReasons: response,
                loading: false
              });
            }).catch(function(error) {
              me.props.route.params.showError(error);
              me.setState({
                loading: false
              });
            });
          }).catch(function(error) {
            me.props.route.params.showError(error);
            me.setState({
              loading: false
            });
          });
        }).catch(function(error) {
          me.props.route.params.showError(error);
          me.setState({
            loading: false
          });
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    });
  };
  
  setDate(value) {
    let stock = this.state.stock;
    stock.date = value;
    this.setState({
      stock: stock
    });
  };
  
  setStockReasonId(value) {
    let stock = this.state.stock;
    stock.stock_reason_id = value;
    this.setState({
      stock: stock
    });
  };
  
  setObservations(value) {
    let stock = this.state.stock;
    stock.observations = value;
    this.setState({
      stock: stock
    });
  };
  
  setItemId(value) {
    let stockItem = this.state.stockItem;
    stockItem.item_id = value;
    this.setState({
      stockItem: stockItem
    });
  };
  
  setWarehouseId(value) {
    let stockItem = this.state.stockItem;
    stockItem.warehouse_id = value;
    this.setState({
      stockItem: stockItem
    });
  };
  
  setLocationId(value) {
    let stockItem = this.state.stockItem;
    stockItem.location_id = value;
    this.setState({
      stockItem: stockItem
    });
  };
  
  setQuantity(value) {
    let stockItem = this.state.stockItem;
    stockItem.quantity = value;
    this.setState({
      stockItem: stockItem
    });
  };
  
  onAddStockItem() {
    let stock = this.state.stock;
    stock.stock_items.push({
      item_id: this.state.stockItem.item_id,
      item: this.state.items.find(item => item.id == this.state.stockItem.item_id),
      location_id: this.state.stockItem.location_id,
      location: this.state.locations.find(location => location.id == this.state.stockItem.location_id),
      quantity: this.state.stockItem.quantity
    });
    let stockItem = this.state.stockItem;
    stockItem.item_id = "";
    stockItem.warehouse_id = "";
    stockItem.location_id = "";
    stockItem.quantity = "";
    this.setState({
      stock: stock,
      stockItem: stockItem
    });
  };
  
  onDeleteStockItem(index) {
    let stock = this.state.stock;
    stock.stock_items.splice(index, 1);
    this.setState({
      stock: stock
    });
  }
  
  onSavePress() {
    const me = this;
    me.setState({
      loading: true
    });
    if (me.state.stock.id === 0) {
      me.props.route.params.apiRequest.post("stock", {
        date: me.state.stock.date,
        stock_reason_id: me.state.stock.stock_reason_id,
        observations: me.state.stock.observations,
        stock_items: me.state.stock.stock_items
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        let stock = me.state.stock;
        stock.id = response.id;
        me.setState({
          stock: stock,
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
    else {
      me.props.route.params.apiRequest.put(`stock/${me.state.stock.id}`, {
        date: me.state.stock.date,
        stock_reason_id: me.state.stock.stock_reason_id,
        observations: me.state.stock.observations,
        stock_items: me.state.stock.stock_items
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        me.setState({
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  };
  
  onDeletePress() {
    const me = this;
    me.setState({
      loading: true
    });
    me.props.route.params.apiRequest.delete(`stock/${me.state.stock.id}`).then(function(response) {
      me.props.route.params.showSuccess(response.message);
      me.props.navigation.navigate("Movimientos de stock");
    }).catch(function(error) {
      me.props.route.params.showError(error);
      me.setState({
        loading: false
      });
    });
  };
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        {
          !this.state.loading ? (
            <Row className="align-item-center m-0 mt-1">
              <Col xs={12} sm={6}>
                <DateInput 
                  onChange={(value) => this.setDate(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.stock.date} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.stock.stock_reason_id}
                  onValueChange={(value) => this.setStockReasonId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Motivo de movimiento de stock"
                  items={this.state.stockReasons.map((stockReason) => { return { label: stockReason.name, value: stockReason.id } })} />
              </Col>
              <Col xs={12}>
                <DescriptionInput 
                  onChangeText={(value) => this.setObservations(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Observaciones"
                  value={this.state.stock.observations} />
              </Col>
              <Col xs={12}>
                <FormLabel text="Items" />
                <Row className="align-item-center m-0">
                  <Col xs={12} sm={6} md={3}>
                    <Select 
                      selectedValue={this.state.stockItem.item_id}
                      onValueChange={(value) => this.setItemId(value)}
                      editable={!this.state.loading && this.props.route.params.hasWritePermission}
                      placeholder="Item"
                      items={this.state.items.map((item) => { return { label: item.concatenated_code + " - " + item.description + " - " + item.color.name, value: item.id } })} />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Select 
                      selectedValue={this.state.stockItem.warehouse_id}
                      onValueChange={(value) => this.setWarehouseId(value)}
                      editable={!this.state.loading && this.props.route.params.hasWritePermission}
                      placeholder="Almacén"
                      items={this.state.warehouses.map((warehouse) => { return { label: warehouse.code + " - " + warehouse.name, value: warehouse.id } })} />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Select 
                      selectedValue={this.state.stock.location_id}
                      onValueChange={(value) => this.setLocationId(value)}
                      editable={!this.state.loading && this.props.route.params.hasWritePermission}
                      placeholder="Ubicación"
                      items={this.state.locations
                              .filter(location => location.warehouse_id == this.state.stockItem.warehouse_id)
                              .map((location) => { return { label: location.code + " - " + location.name, value: location.id } })} />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <DecimalInput
                      onChangeText={(value) => this.setQuantity(value)}
                      editable={!this.state.loading && this.props.route.params.hasWritePermission}
                      value={this.state.stockItem.quantity}
                      placeholder="Cantidad" />
                  </Col>
                  <Col xs={12}
                       className="text-right">
                    <PrimaryButton 
                      title="AGREGAR"
                      loading={this.state.loading}
                      disabled={!this.state.stockItem.item_id || !this.state.stockItem.warehouse_id || 
                              !this.state.stockItem.location_id || !this.state.stockItem.quantity || 
                              this.state.stockItem.quantity <= 0}
                      visible={this.props.route.params.hasWritePermission}
                      onPress={() => this.onAddStockItem()} />
                  </Col>
                </Row>
                {
                  this.state.stock.stock_items.map((stockItem, index) =>
                    <ListItem 
                      key={stockItem.item_id} 
                      bottomDivider>
                      <ErrorIconButton 
                        icon="close-outline"
                        visible={this.props.route.params.hasWritePermission}
                        onPress={() => this.onDeleteStockItem(index)} />
                      <ListItem.Content>
                        <ListItem.Title>
                          {stockItem.item.concatenated_code} - {stockItem.item.description} - {stockItem.item.color.name}
                        </ListItem.Title>
                        <ListItem.Subtitle>
                          <b>
                            Almacén:
                          </b> {stockItem.location.warehouse.code} - {stockItem.location.warehouse.name}
                          <br/>
                          <b>
                            Ubicación:
                          </b> {stockItem.location.code} - {stockItem.location.name}
                          <br/>
                          <b>
                            Cantidad:
                          </b> {stockItem.quantity}
                        </ListItem.Subtitle>
                      </ListItem.Content>
                    </ListItem>
                  )
                }
              </Col>
              <Col xs="auto"
                   className="ml-auto">
                <ErrorButton 
                  title="ELIMINAR"
                  loading={this.state.loading}
                  visible={this.state.stock.id && this.props.route.params.hasDeletePermission}
                  onPress={() => this.onDeletePress()} />
                <PrimaryButton 
                  title="GUARDAR"
                  loading={this.state.loading}
                  disabled={!this.state.stock.date || !this.state.stock.stock_reason_id || 
                          !this.state.stock.stock_items.length}
                  visible={this.props.route.params.hasWritePermission}
                  onPress={() => this.onSavePress()} />
              </Col>
            </Row>
          ) : (
            <div className="container d-flex align-items-center">
              <LoaderBig />
            </div>
          )  
        }
      </Page>
    );
  };
};

export default Stock;
  