import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cloneDeep from 'lodash/cloneDeep';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import CodeInput from '../components/CodeInput';
import DescriptionInput from '../components/DescriptionInput';
import PrimaryButton from '../components/PrimaryButton';
import ErrorButton from '../components/ErrorButton';
import FormLabel from '../components/FormLabel';
import PickerSwitchButton from '../components/PickerSwitchButton';
import FloatingBackButton from '../components/FloatingBackButton';
import SwitchButton from '../components/SwitchButton';

class StockReason extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stockReason: null
    };
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => { 
      console.log("StockReason componentDidMount fired");
      const me = this;
      me.setState({
        stockReason: cloneDeep(me.props.route.params.stockReason),
        loading: false
      });
    });
  };
  
  setCode(value) {
    let stockReason = this.state.stockReason;
    stockReason.code = value;
    this.setState({
      stockReason: stockReason
    });
  };
  
  setName(value) {
    let stockReason = this.state.stockReason;
    stockReason.name = value;
    this.setState({
      stockReason: stockReason
    });
  };
  
  setIsIncremental(value) {
    let stockReason = this.state.stockReason;
    stockReason.is_incremental = value;
    this.setState({
      stockReason: stockReason
    });
  };
  
  onSavePress() {
    const me = this;
    me.setState({
      loading: true
    });
    if (me.state.stockReason.id === 0) {
      me.props.route.params.apiRequest.post("stock-reason", {
        code: me.state.stockReason.code,
        name: me.state.stockReason.name,
        is_incremental: me.state.stockReason.is_incremental
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        let stockReason = me.state.stockReason;
        stockReason.id = response.id;
        me.setState({
          stockReason: stockReason,
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
    else {
      me.props.route.params.apiRequest.put(`stock-reason/${me.state.stockReason.id}`, {
        code: me.state.stockReason.code,
        name: me.state.stockReason.name,
        is_incremental: me.state.stockReason.is_incremental
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        me.setState({
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  };
  
  onDeletePress() {
    const me = this;
    me.setState({
      loading: true
    });
    me.props.route.params.apiRequest.delete(`stock-reason/${me.state.stockReason.id}`).then(function(response) {
      me.props.route.params.showSuccess(response.message);
      me.props.navigation.navigate("Motivos de stock");
    }).catch(function(error) {
      me.props.route.params.showError(error);
      me.setState({
        loading: false
      });
    });
  };
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        {
          !this.state.loading ? (
            <Row className="align-item-center m-0 mt-1">
              <Col xs={12} sm={6}>
                <CodeInput 
                  autoFocus={true}
                  length="2"
                  onChangeText={(value) => this.setCode(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.stockReason.code} />
              </Col>
              <Col xs={12} sm={6}>
                <DescriptionInput 
                  onChangeText={(value) => this.setName(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.stockReason.name} />
              </Col>
              <Col xs={12} sm={6}>
                <SwitchButton
                  value={this.state.stockReason.is_incremental}
                  text="Es incremental?"
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  onValueChange={(value) => this.setIsIncremental(value)} />
              </Col>
              <Col xs={12} sm={6}></Col>
              <Col xs="auto"
                   className="ml-auto">
                <ErrorButton 
                  title="ELIMINAR"
                  loading={this.state.loading}
                  visible={this.state.stockReason.id && this.props.route.params.hasDeletePermission}
                  onPress={() => this.onDeletePress()} />
                <PrimaryButton 
                  title="GUARDAR"
                  loading={this.state.loading}
                  disabled={!this.state.stockReason.code || !this.state.stockReason.name}
                  visible={this.props.route.params.hasWritePermission}
                  onPress={() => this.onSavePress()} />
              </Col>
            </Row>
          ) : (
            <div className="container d-flex align-items-center">
              <LoaderBig />
            </div>
          )  
        }
      </Page>
    );
  };
};

export default StockReason;