import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

const Loader = (props) => (
  <View style={styles.loader} {...props}>
    <ActivityIndicator size="small" color="#0000ff" />
  </View>
);

const styles = StyleSheet.create({
  loader: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "row",
    padding: 2
  }
});

export default Loader;
  