import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from "react-native";

import Loader from './Loader';

const ErrorButton = ({title, loading, disabled, onPress, visible}) => {
  return (
    visible ? (
      <TouchableOpacity 
        onPress={onPress}
        disabled={disabled || loading}
        style={(disabled || loading) ? styles.errorButtonDisabledContainer : styles.errorButtonContainer}>
        {
          loading ? (
            <Loader />
          ) : (
            <Text style={styles.errorButtonText}>
              {title}
            </Text>
          )
        }
      </TouchableOpacity>
    ) : (
      null
    )
  );
};

const styles = StyleSheet.create({
  errorButtonContainer: {
    elevation: 8,
    backgroundColor: "#E34A4A",
    borderRadius: 25,
    paddingVertical: 10,
    paddingHorizontal: 15,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    display: 'inline-block'
  },
  errorButtonDisabledContainer: {
    elevation: 8,
    backgroundColor: "#cccccc",
    borderRadius: 25,
    paddingVertical: 10,
    paddingHorizontal: 15,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    display: 'inline-block'
  },
  errorButtonText: {
    fontSize: 18,
    color: "#ffffff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase"
  }
});

export default ErrorButton;