import React, { Component, useState } from 'react';
import { Image } from 'react-native';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CONFIG from '../config/Config';

import PrimaryBlockButton from '../components/PrimaryBlockButton';
import EmailInput from '../components/EmailInput';

import logo from '../assets/logo.png';

class Recover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: ""
    };
  };
  
  setLoading(value) {
    this.setState({
      loading: value
    });
  };
  
  setEmail(value) {
    this.setState({
      email: value
    });
  };
  
  onRecoverPress() {
    const me = this;
    me.setLoading(true);
    me.props.route.params.apiRequest.post("auth/recover", {
      email: this.state.email
    }).then(function(response) {
      me.props.route.params.showSuccess(response.message);
      me.setEmail("");
      me.setLoading(false);
    }).catch(function(error) {
      me.props.route.params.showError(error);
      me.setLoading(false);
    });
  };
  
  render() {
    return (
      <div className="container bg-primary d-flex align-items-center">
        <Container className="justify-content-center align-items-center">
          <Row className="justify-content-center mb-5">
            <Col 
              xs={12} 
              sm={10} 
              md={6} 
              lg={4} 
              className="text-center">
              <img 
                src={logo} 
                alt={CONFIG.APP_NAME}
                class="img-fluid" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col 
              xs={12} 
              sm={10} 
              md={6} 
              lg={4} 
              className="text-center p-3">
              <EmailInput 
                onChangeText={(value) => this.setEmail(value)}
                editable={!this.state.loading}
                autoFocus={true} />
              <PrimaryBlockButton 
                title="RECUPERAR CONTRASEÑA"
                loading={this.state.loading}
                disabled={!this.state.email}
                onPress={() => this.onRecoverPress()} />
              <hr/>
              <PrimaryBlockButton 
                title="INICIAR SESIÓN"
                disabled={this.state.loading}
                onPress={() => this.props.navigation.navigate("Login")} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
};

export default Recover;
