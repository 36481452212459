import React from 'react';
import { StyleSheet, View } from "react-native";

const FloatingButtonContainer = (props) => {
  return (
    <View style={styles.floatingButtonContainer}>
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  floatingButtonContainer: {
    position: 'fixed',
    right: 10,
    bottom: 10,
    height: 'auto',
    overflow: 'hiddden'
  }
});

export default FloatingButtonContainer;