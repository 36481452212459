import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cloneDeep from 'lodash/cloneDeep';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import NameInput from '../components/NameInput';
import EmailInput from '../components/EmailInput';
import PrimaryButton from '../components/PrimaryButton';
import ErrorButton from '../components/ErrorButton';
import FormLabel from '../components/FormLabel';
import PickerSwitchButton from '../components/PickerSwitchButton';
import FloatingBackButton from '../components/FloatingBackButton';

class User extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      permissions: [],
      user: null
    };
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => { 
      console.log("User componentDidMount fired");
      const me = this;
      me.setState({
        loading: true
      });
      me.props.route.params.apiRequest.get("permission").then(function(response) {
        me.setState({
          permissions: response,
          user: cloneDeep(me.props.route.params.user),
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    });
  };
  
  setName(value) {
    let user = this.state.user;
    user.name = value;
    this.setState({
      user: user
    });
  };
  
  setEmail(value) {
    let user = this.state.user;
    user.email = value;
    this.setState({
      user: user
    });
  };
  
  togglePermission(value, permission) {
    if (value) {
      this.addPermission(permission);
    }
    else {
      this.removePermission(permission);
    }
  }
  
  hasPermission(permission) {
    let hasPermission = false;
    this.state.user.user_permissions.forEach((up) => {
      if (up.permission_id === permission.id) {
        hasPermission = true;
      }
    });
    return hasPermission;
  };
  
  addPermission(permission) {
    const user = this.state.user;
    user.user_permissions.push({
      id: 0,
      user_id: user.id,
      permission_id: permission.id,
      granted: 1
    });
    this.setState({
      user: user
    });
  };
  
  removePermission(permission) {
    const user = this.state.user;
    for (let i = 0; i < user.user_permissions.length; i++) {
      if (user.user_permissions[i].permission_id === permission.id) {
        user.user_permissions.splice(i, 1);
      }
    }
    this.setState({
      user: user
    });
  };
  
  setGranted(permission, value) {
    const user = this.state.user;
    for (let i = 0; i < user.user_permissions.length; i++) {
      if (user.user_permissions[i].permission_id === permission.id) {
        user.user_permissions[i].granted = value;
      }
    }
    this.setState({
      user: user
    });
  };
  
  getGranted(permission) {
    let granted = 0;
    this.state.user.user_permissions.forEach((up) => {
      if (up.permission_id === permission.id) {
        granted = up.granted;
      }
    });
    return granted;
  };
  
  onSavePress() {
    const me = this;
    me.setState({
      loading: true
    });
    if (me.state.user.id === 0) {
      me.props.route.params.apiRequest.post("user", {
        name: me.state.user.name,
        email: me.state.user.email,
        user_permissions: me.state.user.user_permissions
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        let user = me.state.user;
        user.id = response.id;
        me.setState({
          user: user,
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
    else {
      me.props.route.params.apiRequest.put(`user/${me.state.user.id}`, {
        name: me.state.user.name,
        email: me.state.user.email,
        user_permissions: me.state.user.user_permissions
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        me.setState({
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  };
  
  onDeletePress() {
    const me = this;
    me.setState({
      loading: true
    });
    me.props.route.params.apiRequest.delete(`user/${me.state.user.id}`).then(function(response) {
      me.props.route.params.showSuccess(response.message);
      me.props.navigation.navigate("Usuarios");
    }).catch(function(error) {
      me.props.route.params.showError(error);
      me.setState({
        loading: false
      });
    });
  };
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        {
          !this.state.loading ? (
            <Row className="align-item-center m-0 mt-1">
              <Col xs={12} sm={6}>
                <NameInput 
                  autoFocus={true}
                  onChangeText={(value) => this.setName(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.user.name} />
              </Col>
              <Col xs={12} sm={6}>
                <EmailInput 
                  onChangeText={(value) => this.setEmail(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.user.email} />
              </Col>
              <Col xs={12}>
                <FormLabel text="Permisos" />
                <Row>
                  {
                    this.state.permissions.map((permission) => (
                      <Col xs={12} sm={6} md={4} lg={3} key={permission.id}>
                        <PickerSwitchButton 
                          text={permission.name}
                          switchValue={this.hasPermission(permission)}
                          switchText={permission.name}
                          onSwitchValueChange={(value) => this.togglePermission(value, permission)}
                          pickerSelectedValue={this.getGranted(permission)}
                          onPickerValueChange={(value, index) => this.setGranted(permission, value)}
                          editable={!this.state.loading && this.props.route.params.hasWritePermission}
                          pickerItems={[
                            { label: "Lectura", value: 1 },
                            { label: "Escritura", value: 2 },
                            { label: "Control total", value: 3 }
                          ]} />
                      </Col>
                    ))
                  }
                </Row>
              </Col>
              <Col xs="auto" className="ml-auto">
                <ErrorButton 
                  title="ELIMINAR"
                  loading={this.state.loading}
                  visible={this.state.user.id && this.props.route.params.hasDeletePermission}
                  onPress={() => this.onDeletePress()} />
                <PrimaryButton 
                  title="GUARDAR"
                  loading={this.state.loading}
                  disabled={!this.state.user.name || !this.state.user.email}
                  visible={this.props.route.params.hasWritePermission}
                  onPress={() => this.onSavePress()} />
              </Col>
            </Row>
          ) : (
            <div className="container d-flex align-items-center">
              <LoaderBig />
            </div>
          )  
        }
      </Page>
    );
  };
};

export default User;