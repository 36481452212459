import React from 'react';
import { StyleSheet } from "react-native";  
import { Input } from "react-native-elements";

const EmailInput = ({value, editable, autoFocus, onChangeText}) => {
  return (
    <Input 
      onChangeText={onChangeText}
      value={value}
      disabled={!editable}
      placeholder="Correo electrónico"
      autoCompleteType="email"
      autoFocus={autoFocus}
      keyboardType="email-address"
      textContentType="emailAddress"
      style={styles.emailInput}
      maxLength="255" />
  );
};

const styles = StyleSheet.create({
  emailInput: {
    color: "#000000",
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    paddingHorizontal: 10
  }
});

export default EmailInput;