import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cloneDeep from 'lodash/cloneDeep';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import CodeInput from '../components/CodeInput';
import DescriptionInput from '../components/DescriptionInput';
import PrimaryButton from '../components/PrimaryButton';
import ErrorButton from '../components/ErrorButton';
import FormLabel from '../components/FormLabel';
import PickerSwitchButton from '../components/PickerSwitchButton';
import FloatingBackButton from '../components/FloatingBackButton';

class ProductState extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      productState: null
    };
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => { 
      console.log("ProductState componentDidMount fired");
      const me = this;
      me.setState({
        productState: cloneDeep(me.props.route.params.productState),
        loading: false
      });
    });
  };
  
  setCode(value) {
    let productState = this.state.productState;
    productState.code = value;
    this.setState({
      productState: productState
    });
  };
  
  setName(value) {
    let productState = this.state.productState;
    productState.name = value;
    this.setState({
      productState: productState
    });
  };
  
  onSavePress() {
    const me = this;
    me.setState({
      loading: true
    });
    if (me.state.productState.id === 0) {
      me.props.route.params.apiRequest.post("product-state", {
        code: me.state.productState.code,
        name: me.state.productState.name
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        let productState = me.state.productState;
        productState.id = response.id;
        me.setState({
          productState: productState,
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
    else {
      me.props.route.params.apiRequest.put(`product-state/${me.state.productState.id}`, {
        code: me.state.productState.code,
        name: me.state.productState.name
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        me.setState({
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  };
  
  onDeletePress() {
    const me = this;
    me.setState({
      loading: true
    });
    me.props.route.params.apiRequest.delete(`product-state/${me.state.productState.id}`).then(function(response) {
      me.props.route.params.showSuccess(response.message);
      me.props.navigation.navigate("Estados de producto");
    }).catch(function(error) {
      me.props.route.params.showError(error);
      me.setState({
        loading: false
      });
    });
  };
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        {
          !this.state.loading ? (
            <Row className="align-item-center m-0 mt-1">
              <Col xs={12} sm={6}>
                <CodeInput 
                  autoFocus={true}
                  length="2"
                  onChangeText={(value) => this.setCode(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.productState.code} />
              </Col>
              <Col xs={12} sm={6}>
                <DescriptionInput 
                  onChangeText={(value) => this.setName(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.productState.name} />
              </Col>
              <Col xs="auto"
                   className="ml-auto">
                <ErrorButton 
                  title="ELIMINAR"
                  loading={this.state.loading}
                  visible={this.state.productState.id && this.props.route.params.hasDeletePermission}
                  onPress={() => this.onDeletePress()} />
                <PrimaryButton 
                  title="GUARDAR"
                  loading={this.state.loading}
                  disabled={!this.state.productState.code || !this.state.productState.name}
                  visible={this.props.route.params.hasWritePermission}
                  onPress={() => this.onSavePress()} />
              </Col>
            </Row>
          ) : (
            <div className="container d-flex align-items-center">
              <LoaderBig />
            </div>
          )  
        }
      </Page>
    );
  };
};

export default ProductState;