import React, { Component, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Page from '../components/Page';
import FloatingBackButton from '../components/FloatingBackButton';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      password: "",
      password2: ""
    };
  };
  
  setLoading(value) {
    this.setState({
      loading: value
    });
  };
  
  setPassword(value) {
    this.setState({
      password: value
    });
  };
  
  setPassword2(value) {
    this.setState({
      password2: value
    });
  };
  
  onChangePasswordPress() {
    const me = this;
    me.setLoading(true);
    me.props.route.params.apiRequest.post("auth/change-password", {
      id: me.props.route.params.userLogged.id,
      password: me.state.password,
      password2: me.state.password2
    }).then(function(response) {
      me.props.route.params.showSuccess(response.message);
      me.setPassword("");
      me.setPassword2("");
      me.setLoading(false);
    }).catch(function(error) {
      me.props.route.params.showError(error);
      me.setLoading(false);
    });
  };
  
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        
      </Page>
    );
  };
};

export default ChangePassword;
  