import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import CloseButton from './CloseButton';
import { DrawerActions } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Ionicons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Menu = (props) => {
  return (
    <DrawerContentScrollView {...props}>
      <Row className="align-items-center m-0">
        <Col>
          <Text style={styles.userName}>
            {props.userLogged.name}
          </Text>
        </Col>
        <Col xs="auto">
          <CloseButton 
            onPress={() => props.navigation.dispatch(DrawerActions.closeDrawer())} />
        </Col>
      </Row>
      <DrawerItemList {...props} />
      <DrawerItem 
        label='Cerrar Sesión'
        onPress={props.onLogoutPress}
        icon={() => <Icon size={20} color="#ffffff" name='log-out-outline'></Icon>}
        style={{backgroundColor: "#E34A4A"}}
        labelStyle={{color: "#ffffff"}}/>
    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  userName: {
    fontSize: 18,
    fontWeight: 'bold'
  }
});

export default Menu;