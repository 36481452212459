import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cloneDeep from 'lodash/cloneDeep';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import PrimaryButton from '../components/PrimaryButton';
import ErrorButton from '../components/ErrorButton';
import FormLabel from '../components/FormLabel';
import PickerSwitchButton from '../components/PickerSwitchButton';
import FloatingBackButton from '../components/FloatingBackButton';
import Select from '../components/Select';
import DecimalInput from '../components/DecimalInput';
import DateInput from '../components/DateInput';
import { ListItem } from 'react-native-elements';
import ErrorIconButton from '../components/ErrorIconButton';

class Quotation extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      quotation: null,
      customers: [],
      quotation_states: [],
      items: [],
      quotationItem: this.DEFAULT_QUOTATION_ITEM
    };
  };
  
  DEFAULT_QUOTATION_ITEM = {
    item_id: "",
    quantity: "",
    unit_price: ""
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => { 
      console.log("Quotation componentDidMount fired");
      const me = this;
      me.setState({
        quotation: cloneDeep(me.props.route.params.quotation),
        loading: true
      });
      me.props.route.params.apiRequest.get("customer").then(function(response) {
        me.setState({
          customers: response
        });
        me.props.route.params.apiRequest.get("quotation_state").then(function(response) {
          me.setState({
            quotation_states: response
          });
          me.props.route.params.apiRequest.get("item").then(function(response) {
            me.setState({
              items: response.filter(item => item.product_type.is_finished_product),
              loading: false
            });
          }).catch(function(error) {
            me.props.route.params.showError(error);
            me.setState({
              loading: false
            });
          });
        }).catch(function(error) {
          me.props.route.params.showError(error);
          me.setState({
            loading: false
          });
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    });
  };
  
  setCustomerId(value) {
    let quotation = this.state.quotation;
    quotation.customer_id = value;
    this.setState({
      quotation: quotation
    });
  };
  
  setDate(value) {
    let quotation = this.state.quotation;
    quotation.date = value;
    this.setState({
      quotation: quotation
    });
  };
  
  setQuotationStateId(value) {
    let quotation = this.state.quotation;
    quotation.quotation_state_id = value;
    this.setState({
      quotation: quotation
    });
  };
  
  setQuotationItemId(value) {
    let quotationItem = this.state.quotationItem;
    quotationItem.item_id = value;
    quotationItem.unit_price = this.state.items.find((item) => item.id == value).unit_price;
    this.setState({
      quotationItem: quotationItem
    });
  };
  
  setQuotationItemQuantity(value) {
    let quotationItem = this.state.quotationItem;
    quotationItem.quantity = value;
    this.setState({
      quotationItem: quotationItem
    });
  };
  
  onAddQuotationItemPress() {
    const qi = this.state.quotation.quotation_items
      .find(quotationItem => quotationItem.item_id == this.state.quotationItem.item_id);
    if (qi) {
      this.props.route.params.showError("El ítem seleccionado ya se encuentra agregado a la cotización.");
    }
    else {
      let quotation = this.state.quotation;
      quotation.quotation_items.push({
        item_id: this.state.quotationItem.item_id,
        item: this.state.items.find(item => item.id == this.state.quotationItem.item_id),
        quantity: this.state.quotationItem.quantity,
        unit_price: this.state.quotationItem.unit_price
      });
      let quotationItem = this.state.quotationItem;
      quotationItem.item_id = "";
      quotationItem.quantity = "";
      quotationItem.unit_price = "";
      this.setState({
        quotation: quotation,
        quotationItem: quotationItem
      });
    }
  };
  
  onQuotationItemDeletePress(index) {
    let quotation = this.state.quotation;
    quotation.quotation_items.splice(index, 1);
    this.setState({
      quotation: quotation
    });
  };
  
  onSavePress() {
    const me = this;
    me.setState({
      loading: true
    });
    if (me.state.quotation.id === 0) {
      me.props.route.params.apiRequest.post("quotation", {
        customer_id: me.state.quotation.customer_id,
        date: me.state.quotation.date,
        quotation_state_id: me.state.quotation.quotation_state_id,
        quotation_items: me.state.quotation.quotation_items
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        let quotation = me.state.quotation;
        quotation.id = response.id;
        me.setState({
          quotation: quotation,
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
    else {
      me.props.route.params.apiRequest.put(`quotation/${me.state.quotation.id}`, {
        customer_id: me.state.quotation.customer_id,
        date: me.state.quotation.date,
        quotation_state_id: me.state.quotation.quotation_state_id,
        quotation_items: me.state.quotation.quotation_items
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        me.setState({
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  };
  
  onDeletePress() {
    const me = this;
    me.setState({
      loading: true
    });
    me.props.route.params.apiRequest.delete(`quotation/${me.state.quotation.id}`).then(function(response) {
      me.props.route.params.showSuccess(response.message);
      me.props.navigation.navigate("Cotizaciones");
    }).catch(function(error) {
      me.props.route.params.showError(error);
      me.setState({
        loading: false
      });
    });
  };
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        {
          !this.state.loading ? (
            <Row className="align-item-center m-0 mt-1">
              <Col xs={12}>
                <Select 
                  selectedValue={this.state.quotation.customer_id}
                  onValueChange={(value) => this.setCustomerId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Cliente"
                  items={this.state.customers.map((customer) => { return { label: customer.name, value: customer.id } })} />
              </Col>
              <Col xs={12} sm={6}>
                <DateInput 
                  onChange={(value) => this.setDate(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.quotation.date} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.quotation.quotation_state_id}
                  onValueChange={(value) => this.setQuotationStateId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Estado"
                  items={this.state.quotation_states.map((quotation_state) => { return { label: quotation_state.name, value: quotation_state.id } })} />
              </Col>
              <Col xs={12}>
                <FormLabel text="Productos" />
                <Row className="align-item-center m-0">
                  <Col xs={12} sm={4}>
                    <Select 
                      selectedValue={this.state.quotationItem.item_id}
                      onValueChange={(value) => this.setQuotationItemId(value)}
                      editable={!this.state.loading && this.props.route.params.hasWritePermission}
                      placeholder="Ítem"
                      items={this.state.items.map((item) => { return { label: item.concatenated_code + " - " + item.description + " - " + item.color.name, value: item.id } })} />
                  </Col>
                  <Col xs={12} sm={4}>
                    <DecimalInput
                      onChangeText={(value) => this.setQuotationItemQuantity(value)}
                      editable={!this.state.loading && this.props.route.params.hasWritePermission}
                      value={this.state.quotationItem.quantity}
                      placeholder="Cantidad" />
                  </Col>
                  <Col xs={12} sm={4}
                    className="text-right">
                    <PrimaryButton 
                      title="AGREGAR"
                      loading={this.state.loading}
                      disabled={!this.state.quotationItem.item_id || !this.state.quotationItem.quantity
                        || this.state.quotationItem.quantity <= 0}
                      visible={this.props.route.params.hasWritePermission}
                      onPress={() => this.onAddQuotationItemPress()} />
                  </Col>
                </Row>
                {
                  this.state.quotation.quotation_items.map((quotationItem, index) =>
                    <ListItem 
                      key={quotationItem.item_id} 
                      bottomDivider>
                      <ErrorIconButton 
                        icon="close-outline"
                        visible={this.props.route.params.hasWritePermission}
                        onPress={() => this.onQuotationItemDeletePress(index)} />
                      <ListItem.Content>
                        <ListItem.Title>
                          {quotationItem.item.concatenated_code} - {quotationItem.item.description} - {quotationItem.item.color.name}
                        </ListItem.Title>
                        <ListItem.Subtitle>
                          <b>
                            Cantidad:
                          </b> {quotationItem.quantity} - 
                          <b>
                            Precio unitario:
                          </b> {quotationItem.unit_price}
                        </ListItem.Subtitle>
                      </ListItem.Content>
                    </ListItem>
                  )
                }
              </Col>
              <Col xs="auto"
                   className="ml-auto">
                <ErrorButton 
                  title="ELIMINAR"
                  loading={this.state.loading}
                  visible={this.state.quotation.id && this.props.route.params.hasDeletePermission}
                  onPress={() => this.onDeletePress()} />
                <PrimaryButton 
                  title="GUARDAR"
                  loading={this.state.loading}
                  disabled={!this.state.quotation.customer_id || !this.state.quotation.date ||
                          !this.state.quotation.quotation_state_id || !this.state.quotation.quotation_items.length}
                  visible={this.props.route.params.hasWritePermission}
                  onPress={() => this.onSavePress()} />
              </Col>
            </Row>
          ) : (
            <div className="container d-flex align-items-center">
              <LoaderBig />
            </div>
          )  
        }
      </Page>
    );
  };
};

export default Quotation;