import React from 'react';
import { StyleSheet, View, Switch, Text } from "react-native";

const SwitchButton = ({value, text, onValueChange, editable}) => {
  return (
    <View style={styles.switchButtonContainer}>
      <Switch 
        trackColor={{ false: "#E34A4A", true: "#263271" }}
        onValueChange={onValueChange}
        value={value}
        disabled={!editable}
        style={styles.switchButton} />
      <Text style={styles.switchButtonText}>
        {text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  switchButtonContainer: {
    flexDirection: 'row',
    paddingVertical: 10
  },
  switchButton: {
    
  },
  switchButtonText: {
    marginLeft: 10
  }
});

export default SwitchButton;