import React from 'react';
import { Header } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Ionicons';

import MenuButton from './MenuButton';

import CONFIG from '../config/Config';

import logo2 from '../assets/logo2.PNG';

const PageHeader = ({navigation, route}) => {
  const icon = route.params.sections ? 
    route.params.sections.find((section) => section.name === route.name).icon :
    "home-outline";
  
  return (
    <Header 
      leftComponent={navigation ? <MenuButton navigation={navigation} /> : null}
      centerComponent={() => <img src={logo2} className="img-fluid" style={{height: "20px"}} />}
      rightComponent={() => <Icon size={20} name={icon} color="#000000"></Icon>}
      statusBarProps={{barStyle: 'light-content'}}
      backgroundColor="#ffffff" />
  );
};

export default PageHeader;