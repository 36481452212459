import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

const InlineMenuButton = ({navigation, name, icon}) => {
  return (
    <TouchableOpacity 
      onPress={() => navigation.navigate(name)}
      style={styles.menuItemButton}>
      <Icon size={50} color="#000000" name={icon}></Icon>
      <Text style={styles.menuItemButtonText}>
        {name}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  menuItemButton: {
    textAlign: 'center',
    backgroundColor: '#D2BE8B',
    padding: 10,
    marginTop: 10
  },
  menuItemButtonText: {
    color: '#000000',
    fontSize: 20,
    marginTop: 10,
    textTransform: 'uppercase',
    fontFamily: 'Gotham'
  }
});

export default InlineMenuButton;