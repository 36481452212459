import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from "react-native";
import Icon from 'react-native-vector-icons/Ionicons';

const CloseButton = ({onPress}) => {
  return (
    <TouchableOpacity 
      onPress={onPress}
      style={styles.closeButtonContainer}>
      <Text style={styles.closeButtonText}>
        <Icon size={18} name='close-outline'></Icon>
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  closeButtonContainer: {
    elevation: 8,
    backgroundColor: "#000000",
    borderRadius: 40,
    paddingVertical: 9,
    paddingHorizontal: 15,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    width: 40,
    height: 40,
    alignSelf: "flex-end",
    marginTop: 10,
    marginBottom: 10
  },
  closeButtonText: {
    color: "#ffffff",
    alignSelf: "center"
  }
});

export default CloseButton;