import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from "react-native";
import Icon from 'react-native-vector-icons/Ionicons';

const FloatingPlusButton = ({visible, disabled, onPress}) => {
  return (
    visible ? (
      <TouchableOpacity 
        onPress={onPress}
        disabled={disabled}
        style={disabled ? styles.floatingPlusButtonDisabledContainer : styles.floatingPlusButtonContainer}>  
        <Icon size={20} color="#ffffff" name='add-outline'></Icon>
      </TouchableOpacity>
    ) : (
      null
    )
  );
};

const styles = StyleSheet.create({
  floatingPlusButtonContainer: {
    elevation: 8,
    backgroundColor: "#263271",
    borderRadius: 25,
    paddingVertical: 10,
    paddingHorizontal: 12,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    marginTop: 5
  },
  floatingPlusButtonDisabledContainer: {
    elevation: 8,
    backgroundColor: "#cccccc",
    borderRadius: 25,
    paddingVertical: 10,
    paddingHorizontal: 12,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    marginTop: 5
  }
});

export default FloatingPlusButton;