import React from 'react';
import { StyleSheet } from "react-native";  
import { Input } from "react-native-elements";

const PasswordInput = ({editable, autoFocus, onChangeText}) => {
  return (
    <Input 
      onChangeText={onChangeText}
      disabled={!editable}
      placeholder="Contraseña"
      autoCompleteType="password"
      autoFocus={autoFocus}
      textContentType="password"
      style={styles.passwordInput}
      secureTextEntry={true} />
  );
};

const styles = StyleSheet.create({
  passwordInput: {
    color: "#000000",
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    paddingHorizontal: 10
  }
});

export default PasswordInput;