import React from 'react';
import { StyleSheet, Text } from 'react-native';

const FormLabel = ({text}) => {
  return (
    <Text style={styles.formLabel}>
      {text}
    </Text>
  );
};

const styles = StyleSheet.create({
  formLabel: {
    fontSize: 18,
    marginHorizontal: 10,
    paddingHorizontal: 10,
  }
});

export default FormLabel;