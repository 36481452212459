import React from 'react';
import { StyleSheet } from "react-native";  
import { Input } from "react-native-elements";

const CodeInput = ({value, editable, autoFocus, onChangeText, length, placeholder}) => {
  return (
    <Input 
      onChangeText={onChangeText}
      value={value}
      disabled={!editable}
      placeholder={placeholder || "Código"}
      autoFocus={autoFocus}
      keyboardType="default"
      style={styles.codeInput}
      autoCapitalize="characters"
      maxLength={length} />
  );
};

const styles = StyleSheet.create({
  codeInput: {
    color: "#000000",
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    paddingHorizontal: 10
  }
});

export default CodeInput;