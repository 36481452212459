import React from 'react';
import Icon from 'react-native-vector-icons/Ionicons';

const MenuButton = ({navigation}) => {
  return (
    <Icon 
      color="#000000" 
      size={20}
      name="menu-outline" 
      onPress={() => navigation.toggleDrawer()} />
  );
};

export default MenuButton;