import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cloneDeep from 'lodash/cloneDeep';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import CodeInput from '../components/CodeInput';
import DescriptionInput from '../components/DescriptionInput';
import PrimaryButton from '../components/PrimaryButton';
import ErrorButton from '../components/ErrorButton';
import FormLabel from '../components/FormLabel';
import PickerSwitchButton from '../components/PickerSwitchButton';
import FloatingBackButton from '../components/FloatingBackButton';
import Select from '../components/Select';

class Location extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      warehouses: [],
      location: null
    };
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => { 
      console.log("Location componentDidMount fired");
      const me = this;
      me.setState({
        loading: true,
        location: cloneDeep(me.props.route.params.location)
      });
      me.props.route.params.apiRequest.get("warehouse").then(function(response) {
        me.setState({
          warehouses: response,
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    });
  };
  
  setCode(value) {
    let location = this.state.location;
    location.code = value;
    this.setState({
      location: location
    });
  };
  
  setName(value) {
    let location = this.state.location;
    location.name = value;
    this.setState({
      location: location
    });
  };
  
  setWarehouseId(value) {
    let location = this.state.location;
    location.warehouse_id = value;
    this.setState({
      location: location
    });
  }
  
  onSavePress() {
    const me = this;
    me.setState({
      loading: true
    });
    if (me.state.location.id === 0) {
      me.props.route.params.apiRequest.post("location", {
        code: me.state.location.code,
        name: me.state.location.name,
        warehouse_id: me.state.location.warehouse_id
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        let location = me.state.location;
        location.id = response.id;
        me.setState({
          location: location,
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
    else {
      me.props.route.params.apiRequest.put(`location/${me.state.location.id}`, {
        code: me.state.location.code,
        name: me.state.location.name,
        warehouse_id: me.state.location.warehouse_id
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        me.setState({
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  };
  
  onDeletePress() {
    const me = this;
    me.setState({
      loading: true
    });
    me.props.route.params.apiRequest.delete(`location/${me.state.location.id}`).then(function(response) {
      me.props.route.params.showSuccess(response.message);
      me.props.navigation.navigate("Ubicaciones");
    }).catch(function(error) {
      me.props.route.params.showError(error);
      me.setState({
        loading: false
      });
    });
  };
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        {
          !this.state.loading ? (
            <Row className="align-item-center m-0 mt-1">
              <Col xs={12} sm={6}>
                <CodeInput 
                  autoFocus={true}
                  length="4"
                  onChangeText={(value) => this.setCode(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.location.code} />
              </Col>
              <Col xs={12} sm={6}>
                <DescriptionInput 
                  onChangeText={(value) => this.setName(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.location.name} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.location.warehouse_id}
                  onValueChange={(value) => this.setWarehouseId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Almacén"
                  items={this.state.warehouses.map((warehouse) => { return { label: warehouse.name, value: warehouse.id } })} />
              </Col>
              <Col xs={12} sm={6}></Col>
              <Col xs="auto"
                   className="ml-auto">
                <ErrorButton 
                  title="ELIMINAR"
                  loading={this.state.loading}
                  visible={this.state.location.id && this.props.route.params.hasDeletePermission}
                  onPress={() => this.onDeletePress()} />
                <PrimaryButton 
                  title="GUARDAR"
                  loading={this.state.loading}
                  disabled={!this.state.location.code || !this.state.location.name || !this.state.location.warehouse_id}
                  visible={this.props.route.params.hasWritePermission}
                  onPress={() => this.onSavePress()} />
              </Col>
            </Row>
          ) : (
            <div className="container d-flex align-items-center">
              <LoaderBig />
            </div>
          )  
        }
      </Page>
    );
  };
};

export default Location;