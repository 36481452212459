import React from 'react';
import { createStackNavigator } from "@react-navigation/stack";

const Stack = createStackNavigator();

const CustomStackNavigator = ({route, navigation}) => {
  return (
    <Stack.Navigator
      initialRouteName={route.params.sections[0].name}
      screenOptions={{ headerShown: false }}>
      {
        route.params.sections.map((section) =>  
          <Stack.Screen
            key={section.name}
            name={section.name}
            component={section.component}
            initialParams={{
              ...route.params,
              hasWritePermission: route.params.hasPermission(section.permission, 2),
              hasDeletePermission: route.params.hasPermission(section.permission, 3)
            }} />
        )
      }
    </Stack.Navigator>
  );
};

export default CustomStackNavigator;