import React, { useState } from 'react';
import { StyleSheet, View } from "react-native";  
import { Input } from "react-native-elements";
import PrimaryIconButton from './PrimaryIconButton';
import ErrorIconButton from './ErrorIconButton';

const FilterInput = ({editable, autoFocus, onSearch}) => {
  const [filter, setFilter] = useState("");
  
  return (
    <View style={styles.container}>
      <View style={styles.filterInputContainer}>
        <Input 
          onChangeText={(value) => setFilter(value)}
          value={filter}
          disabled={!editable}
          placeholder="Filtrar..."
          autoFocus={autoFocus}
          keyboardType="default"
          style={styles.filterInput} />
      </View>
      <PrimaryIconButton
        icon="search-outline"
        loading={false}
        disabled={!editable}
        onPress={() => onSearch(filter)}
        visible={true}></PrimaryIconButton>
      <ErrorIconButton
        icon="close-outline"
        loading={false}
        disabled={!editable || !filter}
        onPress={() => {
          setFilter("");
          onSearch("");
        }}
        visible={true}></ErrorIconButton>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: "row"
  },
  filterInputContainer: {
    flex: '1',
    width: 'auto'
  },
  filterInput: {
    color: "#000000",
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    paddingHorizontal: 10
  }
});

export default FilterInput;