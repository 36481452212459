import React, { Component } from 'react';
import { FlatList } from 'react-native';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import { ListItem } from 'react-native-elements'
import FloatingButtonContainer from '../components/FloatingButtonContainer';
import FloatingPlusButton from '../components/FloatingPlusButton';
import FilterInput from '../components/FilterInput';
import Icon from 'react-native-vector-icons/Ionicons';
import FloatingBackButton from '../components/FloatingBackButton';

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: "",
      items: [],
      page: 1,
      totalPages: null
    };
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => {
      console.log("Items componentDidMount fired");
      this.loadNextPage();
    });
  };
  
  DEFAULT_ITEM = {
    id: 0,
    code: "",
    description: "",
    business_id: "",
    product_id: "",
    product_state_id: "",
    measure_unit_id: "",
    color_id: "",
    provider_id: "",
    produt_type_id: "",
    unit_price: "",
    profile_large: "",
    provider_code: "",
    provider_description: "",
    item_components: []
  };
  
  onSearch(value) {
    this.setState({
      filter: value,
      items: [],
      page: 1,
      totalPages: null
    }, () => this.loadNextPage());
  }
  
  loadNextPage() {
    const me = this;
    if (!me.state.loading && (me.state.totalPages === null || me.state.page < me.state.totalPages)) {
      me.setState({
        loading: true
      });
      const url = me.state.filter ? `item/search/${me.state.page}/${me.state.filter}` : `item/search/${me.state.page}`;
      me.props.route.params.apiRequest.get(url).then(function(response) {
        me.setState({
          items: me.state.items.concat(response.data),
          loading: false,
          page: response.current_page + 1,
          totalPages: response.last_page
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  }
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation} />
          </Col>
        </Row>
        <FilterInput 
          editable={!this.state.loading} 
          autoFocus={true}
          onSearch={(value) => this.onSearch(value)} />
        <FlatList 
          data={this.state.items}
          keyExtractor={(item) => item.id.toString()}
          onEndReachedThreshold={0.1}
          onEndReached={() => this.loadNextPage()}
          ListFooterComponent={() => this.state.loading ? 
            <LoaderBig /> : !this.state.items.length ?
              <div className="alert alert-danger mx-2">
                <Icon size={20} name="warning-outline"></Icon>
                No existen resultados para mostrar.
              </div> : null
          }
          renderItem={
            (item) => {
              return (
                <ListItem 
                  bottomDivider
                  onPress={() => this.props.navigation.navigate("Item", { item: item.item })}>
                  <ListItem.Content>
                    <ListItem.Title>
                      {item.item.concatenated_code}
                    </ListItem.Title>
                    <ListItem.Subtitle>
                      {item.item.code} - {item.item.description}
                      <br/>
                      <b>
                        Negocio:
                      </b> {item.item.business.code} - {item.item.business.name} - <b>
                        Producto:
                      </b> {item.item.product.code} - {item.item.product.name} - <b>
                        Tipo:
                      </b> {item.item.product_type.code} - {item.item.product_type.name} - <b>
                        Color:
                      </b> {item.item.color.code} - {item.item.color.name} - <b>
                        Largo perfil:
                      </b> {item.item.profile_large} - <b>
                        Estado:
                      </b> {item.item.product_state.code} - {item.item.product_state.name} - <b>
                        Unidad:
                      </b> {item.item.measure_unit.code} - {item.item.measure_unit.name} - <b>
                        Proveedor:
                      </b> {item.item.provider ? `${item.item.provider.code} - ${item.item.provider.name}` : '-'}
                    </ListItem.Subtitle>
                  </ListItem.Content>
                  <ListItem.Chevron />
                </ListItem>
              );
            }
          }>
        </FlatList>
        <FloatingButtonContainer>
          <FloatingPlusButton 
            visible={this.props.route.params.hasWritePermission}
            onPress={() => this.props.navigation.navigate("Item", { item: this.DEFAULT_ITEM })} />
        </FloatingButtonContainer>
      </Page>
    );
  };
};

export default Items;