import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cloneDeep from 'lodash/cloneDeep';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import CodeInput from '../components/CodeInput';
import DescriptionInput from '../components/DescriptionInput';
import PrimaryButton from '../components/PrimaryButton';
import ErrorButton from '../components/ErrorButton';
import FormLabel from '../components/FormLabel';
import PickerSwitchButton from '../components/PickerSwitchButton';
import FloatingBackButton from '../components/FloatingBackButton';
import Select from '../components/Select';
import DecimalInput from '../components/DecimalInput';
import { ListItem } from 'react-native-elements';
import ErrorIconButton from '../components/ErrorIconButton';

class Item extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      item: null,
      businesses: [],
      products: [],
      productStates: [],
      measureUnits: [],
      colors: [],
      providers: [],
      productTypes: [],
      components: [],
      itemComponent: this.DEFAULT_ITEM_COMPONENT
    };
  };
  
  DEFAULT_ITEM_COMPONENT = {
    component_id: "",
    quantity: ""
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => { 
      console.log("Item componentDidMount fired");
      const me = this;
      me.setState({
        item: cloneDeep(me.props.route.params.item),
        loading: true
      });
      me.props.route.params.apiRequest.get("business").then(function(response) {
        me.setState({
          businesses: response
        });
        me.props.route.params.apiRequest.get("product").then(function(response) {
          me.setState({
            products: response
          });
          me.props.route.params.apiRequest.get("product-state").then(function(response) {
            me.setState({
              productStates: response
            });
            me.props.route.params.apiRequest.get("measure-unit").then(function(response) {
              me.setState({
                measureUnits: response
              });
              me.props.route.params.apiRequest.get("color").then(function(response) {
                me.setState({
                  colors: response
                });
                me.props.route.params.apiRequest.get("provider").then(function(response) {
                  me.setState({
                    providers: response
                  });
                  me.props.route.params.apiRequest.get("product-type").then(function(response) {
                    me.setState({
                      productTypes: response
                    });
                    me.props.route.params.apiRequest.get("item").then(function(response) {
                      me.setState({
                        components: response.filter(item => !item.product_type.is_finished_product),
                        loading: false
                      });
                    }).catch(function(error) {
                      me.props.route.params.showError(error);
                      me.setState({
                        loading: false
                      });
                    });
                  }).catch(function(error) {
                    me.props.route.params.showError(error);
                    me.setState({
                      loading: false
                    });
                  });
                }).catch(function(error) {
                  me.props.route.params.showError(error);
                  me.setState({
                    loading: false
                  });
                });
              }).catch(function(error) {
                me.props.route.params.showError(error);
                me.setState({
                  loading: false
                });
              });
            }).catch(function(error) {
              me.props.route.params.showError(error);
              me.setState({
                loading: false
              });
            });
          }).catch(function(error) {
            me.props.route.params.showError(error);
            me.setState({
              loading: false
            });
          });
        }).catch(function(error) {
          me.props.route.params.showError(error);
          me.setState({
            loading: false
          });
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    });
  };
  
  setCode(value) {
    let item = this.state.item;
    item.code = value;
    this.setState({
      item: item
    });
  };
  
  setDescription(value) {
    let item = this.state.item;
    item.description = value;
    this.setState({
      item: item
    });
  };
  
  setBusinessId(value) {
    let item = this.state.item;
    item.business_id = value;
    this.setState({
      item: item
    });
  };
  
  setProductId(value) {
    let item = this.state.item;
    item.product_id = value;
    this.setState({
      item: item
    });
  };
  
  setProductStateId(value) {
    let item = this.state.item;
    item.product_state_id = value;
    this.setState({
      item: item
    });
  };
  
  setMeasureUnitId(value) {
    let item = this.state.item;
    item.measure_unit_id = value;
    this.setState({
      item: item
    });
  };
  
  setColorId(value) {
    let item = this.state.item;
    item.color_id = value;
    this.setState({
      item: item
    });
  };
  
  setProviderId(value) {
    let item = this.state.item;
    item.provider_id = value;
    this.setState({
      item: item
    });
  };
  
  setProductTypeId(value) {
    let item = this.state.item;
    item.product_type_id = value;
    item.product_type = this.state.productTypes.find(pt => pt.id == value);
    this.setState({
      item: item
    });
  };
  
  setUnitPrice(value) {
    let item = this.state.item;
    item.unit_price = value;
    this.setState({
      item: item
    });
  };
  
  setProfileLarge(value) {
    let item = this.state.item;
    item.profile_large = value;
    this.setState({
      item: item
    });
  };
  
  setProviderCode(value) {
    let item = this.state.item;
    item.provider_code = value;
    this.setState({
      item: item
    });
  };
  
  setProviderDescription(value) {
    let item = this.state.item;
    item.provider_description = value;
    this.setState({
      item: item
    });
  };
  
  setItemComponentId(value) {
    let itemComponent = this.state.itemComponent;
    itemComponent.component_id = value;
    this.setState({
      itemComponent: itemComponent
    });
  };
  
  setItemComponentQuantity(value) {
    let itemComponent = this.state.itemComponent;
    itemComponent.quantity = value;
    this.setState({
      itemComponent: itemComponent
    });
  };
  
  onAddItemComponentPress() {
    const ic = this.state.item.item_components
      .find(itemComponent => itemComponent.component_id == this.state.itemComponent.component_id);
    if (ic) {
      this.props.route.params.showError("El componente seleccionado ya se encuentra agregado a la lista de fabricación.");
    }
    else {
      let item = this.state.item;
      item.item_components.push({
        component_id: this.state.itemComponent.component_id,
        component: this.state.components.find(component => component.id == this.state.itemComponent.component_id),
        quantity: this.state.itemComponent.quantity
      });
      let itemComponent = this.state.itemComponent;
      itemComponent.component_id = "";
      itemComponent.quantity = "";
      this.setState({
        item: item,
        itemComponent: itemComponent
      });
    }
  };
  
  onItemComponentDeletePress(index) {
    let item = this.state.item;
    item.item_components.splice(index, 1);
    this.setState({
      item: item
    });
  };
  
  onSavePress() {
    const me = this;
    me.setState({
      loading: true
    });
    if (me.state.item.id === 0) {
      me.props.route.params.apiRequest.post("item", {
        code: me.state.item.code,
        description: me.state.item.description,
        business_id: me.state.item.business_id,
        product_id: me.state.item.product_id,
        product_state_id: me.state.item.product_state_id,
        measure_unit_id: me.state.item.measure_unit_id,
        color_id: me.state.item.color_id,
        provider_id: me.state.item.provider_id,
        product_type_id: me.state.item.product_type_id,
        unit_price: me.state.item.unit_price,
        profile_large: me.state.item.profile_large,
        provider_code: me.state.item.provider_code,
        provider_description: me.state.item.provider_description,
        item_components: me.state.item.item_components
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        let item = me.state.item;
        item.id = response.id;
        me.setState({
          item: item,
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
    else {
      me.props.route.params.apiRequest.put(`item/${me.state.item.id}`, {
        code: me.state.item.code,
        description: me.state.item.description,
        business_id: me.state.item.business_id,
        product_id: me.state.item.product_id,
        product_state_id: me.state.item.product_state_id,
        measure_unit_id: me.state.item.measure_unit_id,
        color_id: me.state.item.color_id,
        provider_id: me.state.item.provider_id,
        product_type_id: me.state.item.product_type_id,
        unit_price: me.state.item.unit_price,
        profile_large: me.state.item.profile_large,
        provider_code: me.state.item.provider_code,
        provider_description: me.state.item.provider_description,
        item_components: me.state.item.item_components
      }).then(function(response) {
        me.props.route.params.showSuccess(response.message);
        me.setState({
          loading: false
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  };
  
  onDeletePress() {
    const me = this;
    me.setState({
      loading: true
    });
    me.props.route.params.apiRequest.delete(`item/${me.state.item.id}`).then(function(response) {
      me.props.route.params.showSuccess(response.message);
      me.props.navigation.navigate("Items");
    }).catch(function(error) {
      me.props.route.params.showError(error);
      me.setState({
        loading: false
      });
    });
  };
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        {
          !this.state.loading ? (
            <Row className="align-item-center m-0 mt-1">
              <Col xs={12} sm={6}>
                <CodeInput 
                  autoFocus={true}
                  length="5"
                  onChangeText={(value) => this.setCode(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.item.code} />
              </Col>
              <Col xs={12} sm={6}>
                <DescriptionInput 
                  onChangeText={(value) => this.setDescription(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.item.description} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.item.business_id}
                  onValueChange={(value) => this.setBusinessId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Negocio"
                  items={this.state.businesses.map((business) => { return { label: business.name, value: business.id } })} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.item.product_id}
                  onValueChange={(value) => this.setProductId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Producto"
                  items={this.state.products.map((product) => { return { label: product.name, value: product.id } })} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.item.product_type_id}
                  onValueChange={(value) => this.setProductTypeId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Tipo"
                  items={this.state.productTypes.map((productType) => { return { label: productType.name, value: productType.id } })} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.item.color_id}
                  onValueChange={(value) => this.setColorId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Color"
                  items={this.state.colors.map((color) => { return { label: color.name, value: color.id } })} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.item.product_state_id}
                  onValueChange={(value) => this.setProductStateId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Estado"
                  items={this.state.productStates.map((productState) => { return { label: productState.name, value: productState.id } })} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.item.measure_unit_id}
                  onValueChange={(value) => this.setMeasureUnitId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Unidad de medida"
                  items={this.state.measureUnits.map((measureUnit) => { return { label: measureUnit.name, value: measureUnit.id } })} />
              </Col>
              <Col xs={12} sm={6}>
                <CodeInput 
                  length="3"
                  placeholder="Largo Perfil"
                  onChangeText={(value) => this.setProfileLarge(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.item.profile_large} />
              </Col>
              <Col xs={12} sm={6}>
                <Select 
                  selectedValue={this.state.item.provider_id || ""}
                  onValueChange={(value) => this.setProviderId(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  placeholder="Proveedor"
                  items={this.state.providers.map((provider) => { return { label: provider.name, value: provider.id } })} />
              </Col>
              <Col xs={12} sm={6}>
                <CodeInput 
                  length="20"
                  placeholder="Código Proveedor"
                  onChangeText={(value) => this.setProviderCode(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.item.provider_code} />
              </Col>
              <Col xs={12} sm={6}>
                <DescriptionInput 
                  placeholder="Descripción Proveedor"
                  onChangeText={(value) => this.setProviderDescription(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.item.provider_description} />
              </Col>
              <Col xs={12} sm={6}>
                <DecimalInput
                  onChangeText={(value) => this.setUnitPrice(value)}
                  editable={!this.state.loading && this.props.route.params.hasWritePermission}
                  value={this.state.item.unit_price}
                  placeholder="Precio unitario" />
              </Col>
              <Col xs={12} sm={6}></Col>
              {
                (this.state.item.product_type && this.state.item.product_type.is_finished_product) ?
                  <Col xs={12}>
                    <FormLabel text="Fabricación" />
                    <Row className="align-item-center m-0">
                      <Col xs={12} sm={4}>
                        <Select 
                          selectedValue={this.state.itemComponent.component_id}
                          onValueChange={(value) => this.setItemComponentId(value)}
                          editable={!this.state.loading && this.props.route.params.hasWritePermission}
                          placeholder="Componente"
                          items={this.state.components.map((component) => { return { label: component.concatenated_code + " - " + component.description + " - " + component.color.name, value: component.id } })} />
                      </Col>
                      <Col xs={12} sm={4}>
                        <DecimalInput
                          onChangeText={(value) => this.setItemComponentQuantity(value)}
                          editable={!this.state.loading && this.props.route.params.hasWritePermission}
                          value={this.state.itemComponent.quantity}
                          placeholder="Cantidad" />
                      </Col>
                      <Col xs={12} sm={4}
                        className="text-right">
                        <PrimaryButton 
                          title="AGREGAR"
                          loading={this.state.loading}
                          disabled={!this.state.itemComponent.component_id || !this.state.itemComponent.quantity
                            || this.state.itemComponent.quantity <= 0}
                          visible={this.props.route.params.hasWritePermission}
                          onPress={() => this.onAddItemComponentPress()} />
                      </Col>
                    </Row>
                    {
                      this.state.item.item_components.map((itemComponent, index) =>
                        <ListItem 
                          key={itemComponent.component_id} 
                          bottomDivider>
                          <ErrorIconButton 
                            icon="close-outline"
                            visible={this.props.route.params.hasWritePermission}
                            onPress={() => this.onItemComponentDeletePress(index)} />
                          <ListItem.Content>
                            <ListItem.Title>
                              {itemComponent.component.concatenated_code} - {itemComponent.component.description} - {itemComponent.component.color.name}
                            </ListItem.Title>
                            <ListItem.Subtitle>
                              <b>
                                Cantidad:
                              </b> {itemComponent.quantity}
                            </ListItem.Subtitle>
                          </ListItem.Content>
                        </ListItem>
                      )
                    }
                  </Col> : 
                  null
              }
              <Col xs="auto"
                   className="ml-auto">
                <ErrorButton 
                  title="ELIMINAR"
                  loading={this.state.loading}
                  visible={this.state.item.id && this.props.route.params.hasDeletePermission}
                  onPress={() => this.onDeletePress()} />
                <PrimaryButton 
                  title="GUARDAR"
                  loading={this.state.loading}
                  disabled={!this.state.item.code || !this.state.item.description || !this.state.item.business_id
                    || !this.state.item.product_id || !this.state.item.product_type_id || !this.state.item.color_id
                    || !this.state.item.product_state_id || !this.state.item.measure_unit_id}
                  visible={this.props.route.params.hasWritePermission}
                  onPress={() => this.onSavePress()} />
              </Col>
            </Row>
          ) : (
            <div className="container d-flex align-items-center">
              <LoaderBig />
            </div>
          )  
        }
      </Page>
    );
  };
};

export default Item;