import React from 'react';
import { StyleSheet, View, Switch, Text } from "react-native";
import { Picker } from '@react-native-picker/picker';

const PickerSwitchButton = ({text, switchValue, onSwitchValueChange, pickerSelectedValue, 
  onPickerValueChange, pickerItems, editable}) => {
  return (
    <View style={styles.switchButtonContainer}>
      <Switch 
        trackColor={{ false: "#E34A4A", true: "#263271" }}
        onValueChange={onSwitchValueChange}
        value={switchValue}
        style={styles.switchButton}
        disabled={!editable} />
      <Text style={styles.switchButtonText}>
        {text}
      </Text>
      {
        switchValue ? (
          <Picker 
            selectedValue={pickerSelectedValue}
            onValueChange={onPickerValueChange}
            style={styles.picker}
            disabled={!editable}>
            {
              pickerItems.map((pickerItem) => (
                <Picker.Item 
                  label={pickerItem.label}
                  value={pickerItem.value}
                  key={pickerItem.value} />
              ))
            }
          </Picker>
        ) : (
          null
        )
      }
    </View>
  );
};

const styles = StyleSheet.create({
  switchButtonContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 10,
    marginHorizontal: 10,
    alignItems: 'center'
  },
  switchButton: {
    
  },
  switchButtonText: {
    marginLeft: 10
  },
  picker: {
    marginLeft: 10
  }
});

export default PickerSwitchButton;