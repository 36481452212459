// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../AppData/Roaming/npm/node_modules/expo-cli/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Gotham/Gotham-Light.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"Gotham\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");\n}\n\nbody {\n  font-family: 'Gotham', sans-serif !important;\n  font-size: 1rem;\n}\n\n.container {\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n}\n\n@media (min-width: 1200px) {\n  .container, .container-lg, .container-md, .container-sm, .container-xl {\n    max-width: none;\n  }\n}\n@media (min-width: 992px) {\n  .container, .container-lg, .container-md, .container-sm {\n    max-width: none;\n  }\n}\n@media (min-width: 768px) {\n  .container, .container-md, .container-sm {\n    max-width: none;\n  }\n}\n@media (min-width: 576px) {\n  .container, .container-sm {\n    max-width: none;\n  }\n}\n\na:focus, a:hover, a:visited, a:link, a:active {\n  text-decoration: none;\n}\n\nhr {\n  margin-left: 10px;\n  margin-right: 10px;\n}\n\n.bg-primary {\n  background-color: #000000 !important;\n}\ninput {\n  background-color: #000000;\n  color: #7B7A7C !important;\n}\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover, \ninput:-webkit-autofill:focus,\ntextarea:-webkit-autofill,\ntextarea:-webkit-autofill:hover,\ntextarea:-webkit-autofill:focus,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus {\n  background-color: #000000;\n  -webkit-text-fill-color: #7B7A7C;\n  -webkit-box-shadow: 0 0 0 30px #000000 inset;\n}", ""]);
// Exports
module.exports = exports;
