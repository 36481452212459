import React, { Component, useState } from 'react';
import { Image } from 'react-native';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CONFIG from '../config/Config';

import PrimaryBlockButton from '../components/PrimaryBlockButton';
import EmailInput from '../components/EmailInput';
import PasswordInput from '../components/PasswordInput';

import logo from '../assets/logo.png';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      password: "",
      rememberMe: false
    };
  };
  
  setLoading(value) {
    this.setState({
      loading: value
    });
  };
  
  setEmail(value) {
    this.setState({
      email: value
    });
  };
  
  setPassword(value) {
    this.setState({
      password: value
    });
  };
  
  onLoginPress() {
    const me = this;
    me.setLoading(true);
    me.props.route.params.apiRequest.post("auth/login", {
      email: me.state.email,
      password: me.state.password,
      remember_me: me.state.rememberMe
    }).then((response) => {
      me.props.route.params.setUserToken(response.access_token);
      me.props.route.params.apiRequest.get("auth/user").then((response) => {
        me.setLoading(false);
        me.props.route.params.setUser(response);
      }).catch((error) => {
        me.setLoading(false);
        me.props.route.params.showError(error);
      });
    }).catch((error) => {
      me.setLoading(false);
      me.props.route.params.showError(error);
    });
  };
  
  render() {
    return (
      <div className="container bg-primary d-flex align-items-center">
        <Container className="justify-content-center align-items-center">
          <Row className="justify-content-center mb-5">
            <Col xs={12} sm={10} md={6} lg={4} className="text-center">
              <img 
                src={logo} 
                alt={CONFIG.APP_NAME}
                className="img-fluid" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} sm={10} md={6} lg={4} className="text-center p-3">
              <EmailInput 
                onChangeText={(email) => this.setEmail(email)}
                editable={!this.state.loading}
                autoFocus={true} />
              <PasswordInput 
                onChangeText={(password) => this.setPassword(password)}
                editable={!this.state.loading} />
              <PrimaryBlockButton 
                title="INGRESAR"
                loading={this.state.loading}
                onPress={() => this.onLoginPress()} />
              <hr/>
              <PrimaryBlockButton 
                title="OLVIDÉ MI CONTRASEÑA"
                disabled={this.state.loading}
                onPress={() => this.props.navigation.navigate("Recover")} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
};

export default Login;