import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from "react-native";

import Loader from './Loader';

const PrimaryButton = ({title, loading, disabled, onPress, visible}) => {
  return (
    visible ? (
      <TouchableOpacity 
        onPress={onPress}
        disabled={disabled || loading}
        style={(disabled || loading) ? styles.primaryButtonDisabledContainer : styles.primaryButtonContainer}>
        {
          loading ? 
            <Loader /> :
            <Text style={styles.primaryButtonText}>
              {title}
            </Text>
        }
      </TouchableOpacity>
    ) : (
      null
    )
  );
};

const styles = StyleSheet.create({
  primaryButtonContainer: {
    elevation: 8,
    backgroundColor: "#263271",
    borderRadius: 25,
    paddingVertical: 10,
    paddingHorizontal: 15,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    marginHorizontal: 10,
    display: 'inline-block'
  },
  primaryButtonDisabledContainer: {
    elevation: 8,
    backgroundColor: "#cccccc",
    borderRadius: 25,
    paddingVertical: 10,
    paddingHorizontal: 15,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    marginHorizontal: 10,
    display: 'inline-block'
  },
  primaryButtonText: {
    fontSize: 18,
    color: "#ffffff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase"
  }
});

export default PrimaryButton;