import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Page from '../components/Page';
import InlineMenuButton from '../components/InlineMenuButton';

class Home extends Component {
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0 mt-5">
          {
            this.props.route.params.modules.map((module) =>
              <Col xs={12} sm={12} md={6} lg={4} xl={3} key={module.name}>
                <InlineMenuButton 
                  navigation={this.props.navigation} 
                  name={module.name} 
                  icon={module.icon} />
              </Col>
            )
          }
        </Row>
      </Page>
    );
  };
};

export default Home;