import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from "react-native";
import Icon from 'react-native-vector-icons/Ionicons';

import Loader from './Loader';

const PrimaryIconButton = ({icon, loading, disabled, onPress, visible}) => {
  return (
    visible ? (
      <TouchableOpacity 
        onPress={onPress}
        disabled={disabled || loading}
        style={(disabled || loading) ? styles.primaryButtonDisabledContainer : styles.primaryButtonContainer}>
        {
          loading ? (
            <Loader />
          ) : (
            <Icon size={20} name={icon} color="#ffffff"></Icon>
          )
        }
      </TouchableOpacity>
    ) : (
      null
    )
  );
};

const styles = StyleSheet.create({
  primaryButtonContainer: {
    elevation: 8,
    backgroundColor: "#rgb(38, 50, 113)",
    borderRadius: 25,
    paddingVertical: 9,
    paddingHorizontal: 5,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    display: 'inline-block',
    height: '40px',
    width: '40px',
    textAlign: 'center',
  },
  primaryButtonDisabledContainer: {
    elevation: 8,
    backgroundColor: "#cccccc",
    borderRadius: 25,
    paddingVertical: 9,
    paddingHorizontal: 5,
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    display: 'inline-block',
    height: '40px',
    width: '40px',
    textAlign: 'center',
  }
});

export default PrimaryIconButton;