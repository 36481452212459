import React, { Component } from 'react';
import { FlatList } from 'react-native';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import { ListItem } from 'react-native-elements'
import FloatingButtonContainer from '../components/FloatingButtonContainer';
import FloatingPlusButton from '../components/FloatingPlusButton';
import FloatingBackButton from '../components/FloatingBackButton';
import FilterInput from '../components/FilterInput';
import Icon from 'react-native-vector-icons/Ionicons';

class Warehouses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: "",
      warehouses: [],
      page: 1,
      totalPages: null
    };
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => { 
      console.log("Warehouses componentDidMount fired");
      this.loadNextPage();
    });
  };
  
  DEFAULT_WAREHOUSE = {
    id: 0,
    code: "",
    name: ""
  };
  
  onSearch(value) {
    this.setState({
      filter: value,
      warehouses: [],
      page: 1,
      totalPages: null
    }, () => this.loadNextPage());
  }
  
  loadNextPage() {
    const me = this;
    if (!me.state.loading && (me.state.totalPages === null || me.state.page < me.state.totalPages)) {
      me.setState({
        loading: true
      });
      const url = me.state.filter ? `warehouse/search/${me.state.page}/${me.state.filter}` : `warehouse/search/${me.state.page}`;
      me.props.route.params.apiRequest.get(url).then(function(response) {
        me.setState({
          warehouses: me.state.warehouses.concat(response.data),
          loading: false,
          page: response.current_page + 1,
          totalPages: response.last_page
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  }
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        <FilterInput 
          editable={!this.state.loading} 
          autoFocus={true}
          onSearch={(value) => this.onSearch(value)} />
        <FlatList 
          data={this.state.warehouses}
          keyExtractor={(warehouse) => warehouse.id.toString()}
          onEndReachedThreshold={0.1}
          onEndReached={() => this.loadNextPage()}
          ListFooterComponent={() => this.state.loading ? 
            <LoaderBig /> : !this.state.warehouses.length ?
              <div className="alert alert-danger mx-2">
                <Icon size={20} name="warning-outline"></Icon>
                No existen resultados para mostrar.
              </div> : null
          }
          renderItem={
            (warehouse) => {
              return (
                <ListItem
                  bottomDivider
                  onPress={() => this.props.navigation.navigate("Almacén", { warehouse: warehouse.item })}>
                  <ListItem.Content>
                    <ListItem.Title>
                      {warehouse.item.code}
                    </ListItem.Title>
                    <ListItem.Subtitle>
                      {warehouse.item.name}
                    </ListItem.Subtitle>
                  </ListItem.Content>
                  <ListItem.Chevron />
                </ListItem>
              );
            }
          }>
        </FlatList>
        <FloatingButtonContainer>
          <FloatingPlusButton 
            visible={this.props.route.params.hasWritePermission}
            disabled={this.state.loading}
            onPress={() => this.props.navigation.navigate("Almacén", { warehouse: this.DEFAULT_WAREHOUSE })} />
        </FloatingButtonContainer>
      </Page>
    );
  };
};

export default Warehouses;