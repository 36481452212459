import 'react-native-gesture-handler';
import React, { Component } from 'react';
import { StyleSheet } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FlashMessage, { showMessage, hideMessage } from "react-native-flash-message";
import Icon from 'react-native-vector-icons/Ionicons';

import CONFIG from './src/config/Config';

import Login from './src/pages/Login';
import Recover from './src/pages/Recover';
import Home from './src/pages/Home';
import Module from './src/pages/Module';
import Businesses from './src/pages/Businesses';
import Business from './src/pages/Business';
import Colors from './src/pages/Colors';
import Color from './src/pages/Color';
import MeasureUnits from './src/pages/MeasureUnits';
import MeasureUnit from './src/pages/MeasureUnit';
import ProductStates from './src/pages/ProductStates';
import ProductState from './src/pages/ProductState';
import ProductTypes from './src/pages/ProductTypes';
import ProductType from './src/pages/ProductType';
import Products from './src/pages/Products';
import Product from './src/pages/Product';
import Providers from './src/pages/Providers';
import Provider from './src/pages/Provider';
import Warehouses from './src/pages/Warehouses';
import Warehouse from './src/pages/Warehouse';
import Locations from './src/pages/Locations';
import Location from './src/pages/Location';
import Items from './src/pages/Items';
import Item from './src/pages/Item';
import Users from './src/pages/Users';
import User from './src/pages/User';
import Customers from './src/pages/Customers';
import Customer from './src/pages/Customer';
import StockReasons from './src/pages/StockReasons';
import StockReason from './src/pages/StockReason';
import Stocks from './src/pages/Stocks';
import Stock from './src/pages/Stock';
import StockReport from './src/pages/StockReport';
import Quotations from './src/pages/Quotations';
import Quotation from './src/pages/Quotation';
import ChangePassword from './src/pages/ChangePassword';

import Menu from './src/components/Menu';
import CustomStackNavigator from './src/components/CustomStackNavigator';

import './node_modules/bootstrap/dist/css/bootstrap.min.css';
import './src/assets/css/style.css';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

export default class App extends Component {
    
  constructor() {
    super();
    this.state = {
      loading: true,
      userToken: null,
      user: null
    };
  };
  
  componentDidMount() {
    this.getSession().then((response) => {
      this.setState({
        userToken: response.userToken,
        user: response.user,
        loading: false
      });
    });
  };
  
  storeSession = () => {
    return new Promise((resolve, reject) => {
      AsyncStorage.setItem('@userToken', this.state.userToken).then(() => {
        AsyncStorage.setItem('@user', JSON.stringify(this.state.user)).then(() => {
          resolve();
        }).catch((error) => {
          console.log(error);
          reject();
        });
      }).catch((error) => {
        console.log(error);
        reject();
      });
    });
  };
  
  removeSession = () => {
    return new Promise((resolve, reject) => {
      AsyncStorage.removeItem('@userToken').then(() => {
        AsyncStorage.removeItem('@user').then(() => {
          resolve();
        }).catch((error) => {
          console.log(error);
          reject();
        });
      }).catch((error) => {
        console.log(error);
        reject();
      });
    });
  };
  
  getSession = () => {
    return new Promise((resolve, reject) => {
      AsyncStorage.getItem('@userToken').then((userToken) => {
        AsyncStorage.getItem('@user').then((user) => {
          resolve({
            user: JSON.parse(user),
            userToken: userToken
          });
        }).catch((error) => {
          console.log(error);
          reject();
        });
      }).catch((error) => {
        console.log(error);
        reject();
      });
    });
  };
  
  setUserToken = (value) => {
    this.setState({
      userToken: value
    });
  };
  
  setUser = (value) => {
    this.setState({
      user: value
    });
    this.storeSession().then(() => {
      console.log("SESSION SAVED");
    });
  };
  
  showError = (value) => {
    showMessage({
      message: "ERROR",
      description: value,
      type: "danger",
      icon: "danger"
    });
  };
  
  showSuccess = (value) => {
    showMessage({
      message: "OK",
      description: value,
      type: "success",
      icon: "success"
    });
  };
  
  onLogout = () => {
    this.removeSession().then(() => {
      console.log("SESSION REMOVED");
      this.setState({
        userToken: null,
        user: null
      });
    });
  };
  
  getHeaders = () => {
    if (this.state.userToken) {
      return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.state.userToken}`
      };
    }
    else {
      return {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      };
    }
  };
  
  apiRequest = () => {
    return {
      get: (url, data = {})  => {
        return new Promise((resolve, reject) => {
          fetch(CONFIG.API_BASE_URL + url, {
            method: "GET",
            headers: this.getHeaders(),
            data: data
          }).then((response) => {
            if (response.status === 200) {
              response.json().then((json) => {
                resolve(json);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else if (response.status === 400) {
              response.json().then((json) => {
                reject(json.message);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else if (response.status === 401) {
              this.onLogout();
              response.json().then((json) => {
                reject(json.message);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else {
              reject(CONFIG.UNEXPECTED_ERROR);
            }
          }).catch(() => {
            reject(CONFIG.UNEXPECTED_ERROR);
          });
        });
      },
      post: (url, body = {}) => {
        return new Promise((resolve, reject) => {
          fetch(CONFIG.API_BASE_URL + url, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(body)
          }).then((response) => {
            if (response.status === 200) {
              response.json().then((json) => {
                resolve(json);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else if (response.status === 400) {
              response.json().then((json) => {
                reject(json.message);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else if (response.status === 401) {
              this.onLogout();
              response.json().then((json) => {
                reject(json.message);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else {
              reject(CONFIG.UNEXPECTED_ERROR);
            }
          }).catch(() => {
            reject(CONFIG.UNEXPECTED_ERROR);
          });
        });
      },
      put: (url, body = {}) => {
        return new Promise((resolve, reject) => {
          fetch(CONFIG.API_BASE_URL + url, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(body)
          }).then((response) => {
            if (response.status === 200) {
              response.json().then((json) => {
                resolve(json);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else if (response.status === 400) {
              response.json().then((json) => {
                reject(json.message);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else if (response.status === 401) {
              this.onLogout();
              response.json().then((json) => {
                reject(json.message);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else {
              reject(CONFIG.UNEXPECTED_ERROR);
            }
          }).catch(() => {
            reject(CONFIG.UNEXPECTED_ERROR);
          });
        });
      },
      delete: (url, data = {}) => {
        return new Promise((resolve, reject) => {
          fetch(CONFIG.API_BASE_URL + url, {
            method: "DELETE",
            headers: this.getHeaders(),
            data: data
          }).then((response) => {
            if (response.status === 200) {
              response.json().then((json) => {
                resolve(json);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else if (response.status === 400) {
              response.json().then((json) => {
                reject(json.message);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else if (response.status === 401) {
              this.onLogout();
              response.json().then((json) => {
                reject(json.message);
              }).catch(() => {
                reject(CONFIG.UNEXPECTED_ERROR);
              });
            }
            else {
              reject(CONFIG.UNEXPECTED_ERROR);
            }
          }).catch(() => {
            reject(CONFIG.UNEXPECTED_ERROR);
          });
        });
      }
    };
  };
  
  hasPermission(code, granted) {
    if (this.state.user) {
      if (code === null) {
        return true;
      }
      else {
        const permission = this.state.user.user_permissions
          .find((up) => up.permission.code === code && up.granted >= granted);
        if (permission) {
          return true;
        }
        else {
          return false;
        }
      }
    }
    else {
      return false;
    }
  };
  
  getModules() {
    const modules = [
      {
        name: "Configuración general",
        icon: "settings-outline",
        sections: [
          {
            name: "Negocios",
            component: Businesses,
            icon: "business-outline",
            permission: "businesses",
            visible: true
          },
          {
            name: "Negocio",
            component: Business,
            icon: "business-outline",
            permission: "businesses",
            visible: false
          }
        ]
      },
      {
        name: "Artículos",
        icon: "pricetag-outline",
        sections: [
          {
            name: "Colores",
            component: Colors,
            icon: "color-palette-outline",
            permission: "colors",
            visible: true
          },
          {
            name: "Color",
            component: Color,
            icon: "color-palette-outline",
            permission: "colors",
            visible: false
          },
          {
            name: "Unidades de medida",
            component: MeasureUnits,
            icon: "analytics-outline",
            permission: "measure-units",
            visible: true
          },
          {
            name: "Unidad de medida",
            component: MeasureUnit,
            icon: "analytics-outline",
            permission: "measure-units",
            visible: false
          },
          {
            name: "Estados de producto",
            component: ProductStates,
            icon: "alert-outline",
            permission: "product-states",
            visible: true
          },
          {
            name: "Estado de producto",
            component: ProductState,
            icon: "alert-outline",
            permission: "product-states",
            visible: false
          },
          {
            name: "Tipos de producto",
            component: ProductTypes,
            icon: "bookmark-outline",
            permission: "product-types",
            visible: true
          },
          {
            name: "Tipo de producto",
            component: ProductType,
            icon: "bookmark-outline",
            permission: "product-types",
            visible: false
          },
          {
            name: "Productos",
            component: Products,
            icon: "cube-outline",
            permission: "products",
            visible: true
          },
          {
            name: "Producto",
            component: Product,
            icon: "cube-outline",
            permission: "products",
            visible: false
          },
          {
            name: "Items",
            component: Items,
            icon: "cube-outline",
            permission: "items",
            visible: true
          },
          {
            name: "Item",
            component: Item,
            icon: "cube-outline",
            permission: "items",
            visible: false
          }
        ]
      },
      {
        name: "Inventario",
        icon: "layers-outline",
        sections: [
          {
            name: "Almacenes",
            component: Warehouses,
            icon: "layers-outline",
            permission: "warehouses",
            visible: true
          },
          {
            name: "Almacén",
            component: Warehouse,
            icon: "layers-outline",
            permission: "warehouses",
            visible: false
          },
          {
            name: "Ubicaciones",
            component: Locations,
            icon: "layers-outline",
            permission: "locations",
            visible: true
          },
          {
            name: "Ubicación",
            component: Location,
            icon: "layers-outline",
            permission: "locations",
            visible: false
          },
          {
            name: "Motivos de stock",
            component: StockReasons,
            icon: "git-compare-outline",
            permission: "stock",
            visible: true
          },
          {
            name: "Motivo de stock",
            component: StockReason,
            icon: "git-compare-outline",
            permission: "stock",
            visible: false
          },
          {
            name: "Movimientos de stock",
            component: Stocks,
            icon: "git-compare-outline",
            permission: "stock",
            visible: true
          },
          {
            name: "Movimiento de stock",
            component: Stock,
            icon: "git-compare-outline",
            permission: "stock",
            visible: false
          },
          {
            name: "Reporte de stock",
            component: StockReport,
            icon: "bar-chart-outline",
            permission: "stock",
            visible: true
          }
        ]
      },
      {
        name: "Compras",
        icon: "cart-outline",
        sections: [
          {
            name: "Proveedores",
            component: Providers,
            icon: "people-outline",
            permission: "providers",
            visible: true
          },
          {
            name: "Proveedor",
            component: Provider,
            icon: "people-outline",
            permission: "providers",
            visible: false
          }
        ]
      },
      {
        name: "Ventas",
        icon: "cart-outline",
        sections: [
          {
            name: "Clientes",
            component: Customers,
            icon: "people-outline",
            permission: "customers",
            visible: true
          },
          {
            name: "Cliente",
            component: Customer,
            icon: "people-outline",
            permission: "customers",
            visible: false
          },
          {
            name: "Cotizaciones",
            component: Quotations,
            icon: "document-text-outline",
            permission: "orders",
            visible: true
          },
          {
            name: "Cotización",
            component: Quotation,
            icon: "document-text-outline",
            permission: "orders",
            visible: false
          }
        ]
      },
      {
        name: "Seguridad",
        icon: "lock-closed-outline",
        sections: [
          {
            name: "Usuarios",
            component: Users,
            icon: "people-outline",
            permission: "users",
            visible: true
          },
          {
            name: "Usuario",
            component: User,
            icon: "people-outline",
            permission: "users",
            visible: false
          },
          {
            name: "Cambiar contraseña",
            component: ChangePassword,
            icon: "lock-closed-outline",
            permission: null,
            visible: true
          }
        ]
      }
    ];
    return modules.filter((module) => {
      const sectionWithoutPermission = module.sections.find((section) => section.permission === null);
      if (sectionWithoutPermission) {
        return true;
      }
      else {
        let hasPermission = false;
        module.sections.forEach((section) => {
          if (this.hasPermission(section.permission, 1)) {
            hasPermission = true;
          }
        });
        return hasPermission;
      }
    });
  };
  
  getInitialParams(module = null) {
    if (module) {
      let sections = [
        {
          name: module.name,
          component: Module,
          icon: module.icon,
          permission: null,
          visible: false
        }
      ].concat(module.sections.filter((section) => this.hasPermission(section.permission, 1)));
      return {
        user: this.state.user,
        apiRequest: this.apiRequest(),
        showError: this.showError,
        showSuccess: this.showSuccess,
        hasPermission: this.hasPermission.bind(this),
        sections: sections
      };
    }
    else {
      return {
        user: this.state.user,
        apiRequest: this.apiRequest(),
        showError: this.showError,
        showSuccess: this.showSuccess,
        hasPermission: this.hasPermission.bind(this)
      };
    }
  }
    
  render() {
    return (
      (!this.state.loading) ? (
        (this.state.userToken && this.state.user) ? (
          <NavigationContainer>
            <Drawer.Navigator
              initialRouteName="Inicio"
              drawerContent={(props) => <Menu {...props} onLogoutPress={this.onLogout} userLogged={this.state.user} />}>
              <Drawer.Screen 
                name="Inicio"
                component={Home}
                options={{drawerIcon: () => <Icon size={20} name="home-outline"></Icon>}}
                initialParams={{
                  ...this.getInitialParams(),
                  modules: this.getModules()
                }} />
              {
                this.getModules().map((module) =>
                  <Drawer.Screen
                    key={module.name}
                    name={module.name}
                    component={CustomStackNavigator}
                    options={{drawerIcon: () => <Icon size={20} name={module.icon}></Icon>}}
                    initialParams={this.getInitialParams(module)} />
                )
              }
            </Drawer.Navigator>
            <FlashMessage position="bottom" />
          </NavigationContainer>
        ) : (
          <NavigationContainer>
            <Stack.Navigator 
              initialRouteName="Login"
              screenOptions={{ headerShown: false }}>
              <Stack.Screen 
                name="Login"
                component={Login}
                initialParams={{
                  apiRequest: this.apiRequest(),
                  setUserToken: this.setUserToken,
                  setUser: this.setUser,
                  showError: this.showError,
                  showSuccess: this.showSuccess
                }} />
              <Stack.Screen 
                name="Recover"
                component={Recover}
                initialParams={{
                  apiRequest: this.apiRequest(),
                  showError: this.showError,
                  showSuccess: this.showSuccess
                }} />
            </Stack.Navigator>
            <FlashMessage position="bottom" />
          </NavigationContainer>
        )
      ) : (null)
    );
  };
  
};
