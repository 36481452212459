import React, { createElement } from 'react';

const DateInput = ({value, onChange, editable}) => {
  const onChangeDate = (e) => {
    onChange(e.target.value);
  };
  
  return createElement('input', {
    type: 'date',
    value: value.substring(0, 10),
    onChange: onChangeDate.bind(this),
    readOnly: !editable,
    style: {
      color: '#000000',
      outline: 'transparent none 0px',
      padding: '10px 10px 5px 10px',
      width: 'calc(100% - 20px)',
      margin: '0px 10px 10px 10px',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: 'solid 1px rgb(134, 147, 158)',
      background: 'transparent',
      minHeight: '40px'
    }
  });
};

export default DateInput;