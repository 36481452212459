import React from 'react';
import { StyleSheet, View } from "react-native";
import { Picker } from '@react-native-picker/picker';

const Select = ({selectedValue, onValueChange, items, editable, placeholder}) => {
  return (
    <View style={styles.pickerContainer}>
      <Picker 
        selectedValue={selectedValue}
        onValueChange={onValueChange}
        style={styles.picker}
        disabled={!editable}>
        <Picker.Item 
          label={placeholder}
          value="" />
        {
          items.map((item) => (
            <Picker.Item 
              label={item.label}
              value={item.value}
              key={item.value} />
          ))
        }
      </Picker>
    </View>
  );
};

const styles = StyleSheet.create({
  pickerContainer: {
    width: 'calc(100% - 20px)',
    borderBottomWidth: '1px',
    borderBottomColor: 'rgb(134, 147, 158)',
    marginHorizontal: '10px',
    marginBottom: '10px'
  },
  picker: {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '18px',
    minHeight: '40px',
    paddingHorizontal: '10px'
  }
});

export default Select;