import React, { Component } from 'react';
import { FlatList } from 'react-native';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Page from '../components/Page';
import LoaderBig from '../components/LoaderBig';
import { ListItem } from 'react-native-elements'
import Icon from 'react-native-vector-icons/Ionicons';
import FloatingBackButton from '../components/FloatingBackButton';
import Select from '../components/Select';

class StockReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      warehouses: [],
      locations: [],
      items: [],
      products: [],
      colors: [],
      businesses: [],
      warehouse_id: 0,
      location_id: 0,
      item_id: 0,
      product_id: 0,
      color_id: 0,
      business_id: 0,
      stocks: [],
      page: 1,
      totalPages: null
    };
  };
  
  componentDidMount() {
    this.focusListener = this.props.navigation.addListener('focus', () => { 
      console.log("Stocks componentDidMount fired");
      const me = this;
      me.setState({
        loading: true
      });
      me.props.route.params.apiRequest.get('warehouse').then(function(response) {
        me.setState({
          warehouses: response
        });
        me.props.route.params.apiRequest.get('location').then(function(response) {
          me.setState({
            locations: response
          });
          me.props.route.params.apiRequest.get('item').then(function(response) {
            me.setState({
              items: response
            });
            me.props.route.params.apiRequest.get('product').then(function(response) {
              me.setState({
                products: response
              });
              me.props.route.params.apiRequest.get('color').then(function(response) {
                me.setState({
                  colors: response
                });
                me.props.route.params.apiRequest.get('business').then(function(response) {
                  me.setState({
                    businesses: response,
                    loading: false
                  });
                  me.loadNextPage();
                }).catch(function(error) {
                  me.props.route.params.showError(error);
                  me.setState({
                    loading: false
                  });
                });
              }).catch(function(error) {
                me.props.route.params.showError(error);
                me.setState({
                  loading: false
                });
              });
            }).catch(function(error) {
              me.props.route.params.showError(error);
              me.setState({
                loading: false
              });
            });
          }).catch(function(error) {
            me.props.route.params.showError(error);
            me.setState({
              loading: false
            });
          });
        }).catch(function(error) {
          me.props.route.params.showError(error);
          me.setState({
            loading: false
          });
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    });
  };
  
  onSearch() {
    this.setState({
      stocks: [],
      page: 1,
      totalPages: null
    }, () => this.loadNextPage());
  };
  
  loadNextPage() {
    const me = this;
    if (!me.state.loading && (me.state.totalPages === null || me.state.page < me.state.totalPages)) {
      me.setState({
        loading: true
      });
      const url = `stock/report/${me.state.warehouse_id}/${me.state.location_id}/${me.state.item_id}/${me.state.product_id}/${me.state.color_id}/${me.state.business_id}/${me.state.page}`;
      me.props.route.params.apiRequest.get(url).then(function(response) {
        me.setState({
          stocks: me.state.stocks.concat(response.data),
          loading: false,
          page: response.current_page + 1,
          totalPages: response.last_page
        });
      }).catch(function(error) {
        me.props.route.params.showError(error);
        me.setState({
          loading: false
        });
      });
    }
  };
  
  setWarehouseId(warehouse_id) {
    if (!warehouse_id) {
      warehouse_id = 0;
    }
    this.setState({
      warehouse_id: warehouse_id
    });
    this.onSearch();
  };
  
  setLocationId(location_id) {
    if (!location_id) {
      location_id = 0;
    }
    this.setState({
      location_id: location_id
    });
    this.onSearch();
  };
  
  setItemId(item_id) {
    if (!item_id) {
      item_id = 0;
    }
    this.setState({
      item_id: item_id
    });
    this.onSearch();
  };
  
  setProductId(product_id) {
    if (!product_id) {
      product_id = 0;
    }
    this.setState({
      product_id: product_id
    });
    this.onSearch();
  };
  
  setColorId(color_id) {
    if (!color_id) {
      color_id = 0;
    }
    this.setState({
      color_id: color_id
    });
    this.onSearch();
  };
  
  setBusinessId(business_id) {
    if (!business_id) {
      business_id = 0;
    }
    this.setState({
      business_id: business_id
    });
    this.onSearch();
  };
    
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton 
              navigation={this.props.navigation}
              disabled={this.state.loading} />
          </Col>
        </Row>
        <Row className="align-items-center m-0">
          <Col xs={12} md={6} lg={4}>
            <Select 
              selectedValue={this.state.warehouse_id}
              onValueChange={(value) => this.setWarehouseId(value)}
              editable={!this.state.loading}
              placeholder="Almacén"
              items={this.state.warehouses.map((warehouse) => { return { label: warehouse.name, value: warehouse.id } })} />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Select 
              selectedValue={this.state.location_id}
              onValueChange={(value) => this.setLocationId(value)}
              editable={!this.state.loading}
              placeholder="Ubicación"
              items={this.state.locations.filter(l => l.warehouse_id == this.state.warehouse_id).map((location) => { 
                return { label: location.name, value: location.id }; 
              })} />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Select 
              selectedValue={this.state.item_id}
              onValueChange={(value) => this.setItemId(value)}
              editable={!this.state.loading}
              placeholder="Articulo"
              items={this.state.items.map((item) => { return { label: item.description, value: item.id } })} />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Select 
              selectedValue={this.state.product_id}
              onValueChange={(value) => this.setProductId(value)}
              editable={!this.state.loading}
              placeholder="Producto"
              items={this.state.products.map((product) => { return { label: product.name, value: product.id } })} />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Select 
              selectedValue={this.state.color_id}
              onValueChange={(value) => this.setColorId(value)}
              editable={!this.state.loading}
              placeholder="Color"
              items={this.state.colors.map((color) => { return { label: color.name, value: color.id } })} />
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Select 
              selectedValue={this.state.business_id}
              onValueChange={(value) => this.setBusinessId(value)}
              editable={!this.state.loading}
              placeholder="Negocio"
              items={this.state.businesses.map((business) => { return { label: business.name, value: business.id } })} />
          </Col>
        </Row>
        <FlatList 
          data={this.state.stocks}
          keyExtractor={(stock) => stock.item_id.toString() + "-" + stock.location_id.toString()}
          onEndReachedThreshold={0.1}
          onEndReached={() => this.loadNextPage()}
          ListFooterComponent={() => this.state.loading ? 
            <LoaderBig /> : !this.state.stocks.length ?
              <div className="alert alert-danger mx-2">
                <Icon size={20} name="warning-outline"></Icon>
                No existen resultados para mostrar.
              </div> : null
          }
          renderItem={
            (stock) => {
              return (
                <ListItem
                  bottomDivider>
                  <ListItem.Content>
                    <ListItem.Title>
                      {stock.item.item.concatenated_code} - {stock.item.item.description} - {stock.item.item.color.name}
                    </ListItem.Title>
                    <ListItem.Subtitle>
                      <b>Cantidad:</b> {stock.item.quantity}
                      <br/>
                      <b>Almacén:</b> {stock.item.location.warehouse.code} - {stock.item.location.warehouse.name}
                      <br/>
                      <b>Ubicación:</b> {stock.item.location.code} - {stock.item.location.name}
                    </ListItem.Subtitle>
                  </ListItem.Content>
                </ListItem>
              );
            }
          }>
        </FlatList>
      </Page>
    );
  };
};

export default StockReport;