import React from 'react';
import { StyleSheet } from "react-native";  
import { Input } from "react-native-elements";

const DescriptionInput = ({value, editable, autoFocus, onChangeText, placeholder}) => {
  return (
    <Input 
      onChangeText={onChangeText}
      value={value}
      disabled={!editable}
      placeholder={placeholder || "Descripción"}
      autoCompleteType="name"
      autoFocus={autoFocus}
      keyboardType="default"
      textContentType="name"
      style={styles.descriptionInput} />
  );
};

const styles = StyleSheet.create({
  descriptionInput: {
    color: "#000000",
    outlineStyle: "none",
    outlineWidth: 0,
    outlineColor: "transparent",
    paddingHorizontal: 10
  }
});

export default DescriptionInput;