import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Page from '../components/Page';
import InlineMenuButton from '../components/InlineMenuButton';
import FloatingButtonContainer from '../components/FloatingButtonContainer';
import FloatingBackButton from '../components/FloatingBackButton';

class Module extends Component {
  render() {
    return (
      <Page {...this.props}>
        <Row className="align-items-center m-0">
          <Col xs={12}>
            <FloatingBackButton navigation={this.props.navigation} />
          </Col>
        </Row>
        <Row className="align-items-center m-0 mt-1">
          {
            this.props.route.params.sections
              .filter((section) => section.visible)
              .map((section) =>
              <Col xs={12} sm={12} md={6} lg={4} xl={3} key={section.name}>
                <InlineMenuButton 
                  navigation={this.props.navigation} 
                  name={section.name} 
                  icon={section.icon} />
              </Col>
            )
          }
        </Row>
      </Page>
    );
  };
};

export default Module;